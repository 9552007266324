import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

// Create an async thunk for fetching magic items
export const fetchMagicItemsAsync = createAsyncThunk(
  'magicItems/fetchItems',
  async () => {
    try {
      // Make an API request to fetch magic items from your Django backend
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/magic-items/');
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

// Create an async thunk for purchasing and adding a magic item
export const purchaseMagicItem = createAsyncThunk(
  'magicItems/purchaseItem',
  async ({ characterId, itemId }, { dispatch }) => {
    try {
      // Log the characterId and itemId before making the API request
      //console.log('characterId:', characterId);
      //console.log('itemId:', itemId);
      
      // Fetch the current amount of gold the character has
      const characterResponse = await fetch(`/v1/characters/${characterId}/`);
      const characterData = await characterResponse.json();
      const currentGold = characterData.Gold;
      //console.log('currentGold:', currentGold);

      // Make an API request to your Django backend to purchase and add the magic item
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/purchaseMagicItem/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ characterId, itemId }),
      });
      const data = await response.json();

      // If the purchase was successful, update the Redux state
      if (data.success) {
        // Dispatch an action to update the character's gold in Redux
        dispatch(updateCharacterGold({ characterId, newGold: data.newGold }));

        // Dispatch an action to add the purchased item to Redux
        dispatch(addMagicItemToCharacter({ characterId, magicItem: data.magicItem }));

        // Dispatch an action to update the yourItems state in the yourItemSlice
        dispatch(addItemToYourItems({ characterId, magicItem: data.magicItem }));
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

// Define action to update character's gold in Redux
export const updateCharacterGold = createAction('magicItems/updateCharacterGold', (payload) => ({
  payload,
}));

// Define action to add purchased magic item to character's inventory in Redux
export const addMagicItemToCharacter = createAction('magicItems/addMagicItemToCharacter', (payload) => ({
  payload,
}));

const magicItemSlice = createSlice({
  name: 'magicItems',
  initialState: {
    magicItems: [], // Change 'items' to 'results'
    status: 'idle', // Initial status
    error: null,
  },
  reducers: {
    // Define reducer to update character's gold in Redux
    [updateCharacterGold.type]: (state, action) => {
      // Find the character in state and update their gold
      const characterId = action.payload.characterId;
      const newGold = action.payload.newGold;
      const characterIndex = state.magicItems.findIndex((character) => character.id === characterId);
      if (characterIndex !== -1) {
        state.magicItems[characterIndex].Gold = newGold;
      }
    },

    // Define reducer to add purchased magic item to character's inventory in Redux
    [addMagicItemToCharacter.type]: (state, action) => {
      // Find the character in state and add the purchased magic item to their inventory
      const characterId = action.payload.characterId;
      const magicItem = action.payload.magicItem;
      const characterIndex = state.magicItems.findIndex((character) => character.id === characterId);
      if (characterIndex !== -1) {
        state.magicItems[characterIndex].inventory.push(magicItem);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMagicItemsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMagicItemsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.magicItems = action.payload.results;
        // Handle success if needed
      })
      .addCase(fetchMagicItemsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Handle error if needed
      })
      .addCase(purchaseMagicItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(purchaseMagicItem.fulfilled, (state) => {
        state.loading = false;
        // Handle success if needed
      })
      .addCase(purchaseMagicItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Handle error if needed
      });
  },
});

export default magicItemSlice.reducer;