import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunk to fetch character logs asynchronously
export const fetchCharacterLogsAsync = createAsyncThunk(
  'characterLogs/fetchCharacterLogs',
  async () => {
    try {
      const accessToken = localStorage.getItem('accessToken'); // Retrieve JWT token from local storage
      console.log('fetchWithUsers accessToken:', accessToken); // Log the token
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/characters-with-users/', {
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Include JWT token in headers
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch character logs');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

// Thunk to create a new character log asynchronously
export const createCharacterLogAsync = createAsyncThunk(
  'characterLogs/createCharacterLog',
  async (logData) => {
    try {
      const accessToken = localStorage.getItem('accessToken'); // Retrieve JWT token from local storage

      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/character-logs/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,

        },
        body: JSON.stringify(logData),
      });
      if (!response.ok) {
        throw new Error('Failed to create character log');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

// Initial state
const initialState = {
  characterLogs: [],
  status: 'idle',
  error: null,
};

// Slice for character logs
const characterLogSlice = createSlice({
  name: 'characterLogs',
  initialState,
  reducers: {
    // Additional reducers can be added here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCharacterLogsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchCharacterLogsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.characterLogs = action.payload;
      })
      .addCase(fetchCharacterLogsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createCharacterLogAsync.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(createCharacterLogAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add the new log to the characterLogs array
        state.characterLogs.push(action.payload);
      })
      .addCase(createCharacterLogAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default characterLogSlice.reducer;
