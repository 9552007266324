// rootReducer.js
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// slices
import userReducer from './slices/user';
import gameReducer from './slices/managegameSlice';
import downtimeReducer from './slices/downtimeSlice';
import characterReducer from './slices/characterSlice';
import magicItemsReducer from './slices/magicItemSlice';
import purchaseItemsReducer from './slices/purchaseItemSlice';
import yourItemsReducer from './slices/yourItemSlice';
import characterLogReducer from './slices/characterLogSlice';
import realEstateReducer from './slices/realEstateSlice';
import tradesReducer from './slices/tradesSlice'; // Import the tradesReducer

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [
    'user',
    'games',
    'downtime',
    'characters',
    'magicItems',
    'purchaseItems',
    'yourItems',
    'characterLogs',
    'realEstate',
    'trades', // Add the trades slice to the whitelist
  ],
};

const rootReducer = combineReducers({
  user: userReducer,
  games: gameReducer,
  downtime: downtimeReducer,
  characters: characterReducer,
  magicItems: magicItemsReducer,
  purchaseItems: purchaseItemsReducer,
  yourItems: yourItemsReducer,
  characterLogs: characterLogReducer,
  realEstate: realEstateReducer,
  trades: tradesReducer, // Add the tradesReducer to the rootReducer
});

export { rootPersistConfig, rootReducer };