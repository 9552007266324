import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const baseURL = 'https://wellmetadmin.wellmet.academy/api/v1';

const apiInstance = axios.create({
  baseURL,
});

apiInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    config.headers['X-CSRFToken'] = Cookies.get('csrftoken');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchGames = async () => {
  try {
    const response = await apiInstance.get('/games');
    console.log('Fetched games data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching games:', error);
    throw error;
  }
};

export const createGame = async (gameData) => {
  try {
    console.log('Creating game with data:', gameData);

    const response = await apiInstance.post('/games/create/', gameData);
    console.log('Game created successfully:', response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error creating game:', error.response.data);
      console.error('Status code:', error.response.status);
      console.error('Headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up the request:', error.message);
    }
    console.error('Request data:', gameData);
    throw error;
  }
};

export const updateGame = async (gameId, gameData) => {
  try {
    const response = await apiInstance.put(`/games/${gameId}`, gameData);
    console.log(`Updated game ${gameId}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error updating game ${gameId}:`, error);
    throw error;
  }
};

export const fetchUsers = async () => {
  try {
    const response = await apiInstance.get('/users');
    console.log('Fetched users data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const addCharactersToGame = async (gameId, selectedCharacterIds) => {
  try {
    const promises = selectedCharacterIds.map(async (characterId) => {
      const response = await apiInstance.post(`/games/${gameId}/add-characters/${characterId}/`);
      console.log(`Added character ${characterId} to game ${gameId}:`, response.data);
      return response.data;
    });
    const results = await Promise.all(promises);
    console.log('All characters added successfully:', results);
    return results;
  } catch (error) {
    console.error('Error adding characters to game:', error);
    throw error;
  }
};

export const fetchCharacterToGameMappings = createAsyncThunk(
  'games/fetchCharacterToGameMappings',
  async (_, { getState }) => {
    try {
      const state = getState();
      const incompleteGames = state.games.games.results.filter((game) => !game.Completed);
      const gameIds = incompleteGames.map((game) => game.ID);

      const response = await apiInstance.get('/character-to-open-game/', {
        params: {
          game_ids: gameIds.join(','),
        },
      });

      console.log('Fetched character-to-game mappings:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching character-to-game mappings:', error);
      throw error;
    }
  }
);

export const fetchUsersAssociatedWithGame = async (gameId) => {
  try {
    const response = await apiInstance.get(`/games/${gameId}/users/`);
    console.log(`Fetched users associated with game ${gameId}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching users associated with game ${gameId}:`, error);
    throw error;
  }
};

export const updateGameCharacters = async (gameId, selectedCharacterIds) => {
  try {
    const allAssociationsResponse = await apiInstance.get('/character-to-game/');
    const allAssociations = allAssociationsResponse.data.results;

    const existingAssociations = allAssociations.filter(association => association.Game_ID === gameId);
    const existingCharacterIds = existingAssociations.map(association => association.Character_ID);

    console.log('Existing associations for game:', existingCharacterIds);

    await Promise.all(selectedCharacterIds.map(async characterId => {
      if (!existingCharacterIds.includes(characterId)) {
        console.log(`Character ${characterId} is not associated with game ${gameId}. Adding...`);

        const putResponse = await apiInstance.put(`/games/${gameId}/add-characters/${characterId}/`);
        console.log(`Added character ${characterId} to game ${gameId}:`, putResponse);
      } else {
        console.log(`Character ${characterId} is already associated with game ${gameId}. Skipping...`);
      }
    }));

    return { success: true, message: 'Characters added to game successfully.' };
  } catch (error) {
    console.error('Error updating game characters:', error);
    throw error;
  }
};

export const removeCharacterFromGame = async (gameId, characterId) => {
  try {
    const response = await apiInstance.delete(`/games/${gameId}/remove-characters/${characterId}`);
    console.log(`Removed character ${characterId} from game ${gameId}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error removing character ${characterId} from game ${gameId}:`, error);
    throw error;
  }
};

export const concludeAndRewardGame = async (gameIds) => {
  try {
    console.log('Concluding and rewarding games:', gameIds);
    console.log('Calling API to conclude and reward games...');

    const csrfToken = Cookies.get('csrftoken');

    const response = await apiInstance.post(
      '/games/conclude-and-reward/',
      { selected_games: gameIds },
      {
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('API call successful. Games concluded and rewarded:', gameIds);

    // Update the Completed field of the rewarded games to true
    const updatedGames = response.data.map((game) => ({
      ...game,
      Completed: true,
    }));

    return updatedGames;
  } catch (error) {
    console.error('Error concluding and rewarding games:', error);
    throw error;
  }
};

// Redux Slice
const gamesSlice = createSlice({
  name: 'games',
  initialState: {
    games: { results: [] },
    status: 'idle',
    error: null,
    characterCounts: {},
    characterToGameMappings: [],
  },
  reducers: {
    updateCharacterCounts: (state, action) => {
      state.characterCounts = action.payload;
      console.log('Updated character counts in Redux store:', state.characterCounts);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGamesAsync.pending, (state) => {
        console.log('Fetching games is pending...');
        state.status = 'loading';
      })
      .addCase(fetchGamesAsync.fulfilled, (state, action) => {
        console.log('Fetching games is fulfilled.');
        console.log('Fetched games data:', action.payload);
        state.status = 'succeeded';
        state.games = action.payload;
      })
      .addCase(fetchGamesAsync.rejected, (state, action) => {
        console.log('Fetching games is rejected:', action.error.message);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createGameAsync.fulfilled, (state, action) => {
        console.log('Creating a game is fulfilled.');
        console.log('Created game data:', action.payload);
        state.games.results.push(action.payload);
      })
      .addCase(handleCharacterSelectionSubmit.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(handleCharacterSelectionSubmit.fulfilled, (state, action) => {
        console.log('Adding characters to game is fulfilled.');
        console.log('Added characters data:', action.payload);
        state.status = 'succeeded';
      })
      .addCase(handleCharacterSelectionSubmit.rejected, (state, action) => {
        console.log('Adding characters to game is rejected:', action.error.message);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(removeCharactersFromGame.fulfilled, (state, action) => {
        console.log('Removing characters from game is fulfilled.');
        console.log('Removed characters data:', action.payload);
        state.status = 'succeeded';
      })
      .addCase(removeCharactersFromGame.rejected, (state, action) => {
        console.log('Removing characters from game is rejected:', action.error.message);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCharacterCounts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.characterCounts = action.payload;
      })
      .addCase(fetchCharacterCounts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(concludeAndRewardGameAction.fulfilled, (state, action) => {
        console.log('Game concluded and rewarded successfully:', action.payload);
        // Update the games state with the updated games
        state.games.results = state.games.results.map((game) => {
          const updatedGame = action.payload.find((updatedGame) => updatedGame.ID === game.ID);
          return updatedGame ? updatedGame : game;
        });
      })
      .addCase(fetchCharacterToGameMappings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCharacterToGameMappings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.characterToGameMappings = action.payload.results;
      })
      .addCase(fetchCharacterToGameMappings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const fetchGamesAsync = createAsyncThunk('games/fetchGames', async () => {
  console.log('Fetching games...');
  console.log('fetchGamesAsync triggered'); // Add this log
  return fetchGames();
});

export const createGameAsync = createAsyncThunk('games/createGame', async (gameData) => {
  console.log('Creating a game...');
  return createGame(gameData);
});

export const updateGameAsync = createAsyncThunk('games/updateGame', async ({ gameId, gameData }) => {
  console.log(`Updating game ${gameId}...`);
  return updateGame(gameId, gameData);
});

export const handleCharacterSelectionSubmit = createAsyncThunk('games/handleCharacterSelectionSubmit', async ({ gameId, selectedCharacterIds }) => {
  try {
    const response = await addCharactersToGame(gameId, selectedCharacterIds);
    return response.data;
  } catch (error) {
    throw Error('Failed to add characters to the game.');
  }
});

export const removeCharactersFromGame = createAsyncThunk(
  'games/removeCharactersFromGame',
  async ({ gameId, characterId }) => {
    try {
      const response = await removeCharacterFromGame(gameId, characterId);
      return response.data;
    } catch (error) {
      console.error(`Error removing character ${characterId} from game ${gameId}:`, error);
      throw error;
    }
  }
);

export const fetchCharacterCounts = createAsyncThunk(
  'games/fetchCharacterCounts',
  async (gameIds) => {
    try {
      const response = await apiInstance.post('/character-counts/', { game_ids: gameIds });
      return response.data;
    } catch (error) {
      console.error('Error fetching character counts:', error);
      throw error;
    }
  }
);

export const concludeAndRewardGameAction = createAsyncThunk(
  'games/concludeAndRewardGame',
  async (gameIds) => {
    try {
      const updatedGames = await concludeAndRewardGame(gameIds);
      console.log('Game concluded and rewarded successfully:', updatedGames);
      return updatedGames;
    } catch (error) {
      console.error('Error concluding and rewarding game:', error);
      throw error;
    }
  }
);

export const { updateCharacterCounts } = gamesSlice.actions;
export default gamesSlice.reducer;