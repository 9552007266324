import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Typography,
  Stack,
  Paper,
  Button,
} from '@mui/material';

const MagicItemDetail = () => {
  const { itemId } = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/magic-items/${itemId}/`);
        console.log('Response status:', response.status);
        const data = await response.json();
        console.log(' Response Data:', data);

        data.Description = data.Description.replace(/\\n/g, '<br>');

        setItem(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching item details:', error);
      }
    };
    fetchItemDetails();
  }, [itemId]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Stack spacing={3}>
        <Card sx={{ p: 1 }}>
          <Stack spacing={1}>
            <Typography variant="overline" sx={{ color: 'text.secondary' }}>
              Magic Item Details
            </Typography>
            {loading ? (
              <Typography>Loading item details...</Typography>
            ) : item ? (
              <div>
                <Typography variant="h5">{item.Name}</Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Type:
                </Typography>
                <Typography variant="body1">{item.Type}</Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Description:
                </Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item.Description }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Cost:
                </Typography>
                <Typography variant="body1">{item.Cost}</Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Rarity:
                </Typography>
                <Typography variant="body1">{item.Rarity}</Typography>
                {item.magic_item_url && (
                  <a href={item.magic_item_url} target="_blank" rel="noopener noreferrer">
                    <Button variant="contained" sx={{ marginTop: '10px' }}>
                      Homebrewery Link
                    </Button>
                  </a>
                )}
                <Button variant="contained" onClick={handleGoBack} sx={{ marginTop: '10px' }}>
                  Go Back
                </Button>
              </div>
            ) : (
              <Typography>Item not found.</Typography>
            )}
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export default MagicItemDetail;