import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Card, Typography, Stack, Paper, Button } from '@mui/material';

const RealEstateDetail = () => {
  const { realEstateId } = useParams();
  const [realEstate, setRealEstate] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRealEstateDetails = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/real-estate/${realEstateId}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
  
        // Replace newline characters with HTML line breaks
        data.Description = data.Description ? data.Description.replace(/\n/g, '<br>') : '';
  
        setRealEstate(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching real estate details:', error);
      }
    };

    fetchRealEstateDetails();
  }, [realEstateId]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Stack spacing={3}>
        <Card sx={{ p: 1 }}>
          <Stack spacing={1}>
            {realEstate && realEstate.image && (
              <img
                src={realEstate.image}
                alt={`Image for ${realEstate.Name}`}
                style={{ maxWidth: '100%', maxHeight: '700px', objectFit: 'contain' }}
              />
            )}
            <Typography variant="overline" sx={{ color: 'text.secondary' }}>
              Real Estate Details
            </Typography>
            {loading ? (
              <Typography>Loading real estate details...</Typography>
            ) : realEstate ? (
              <div>
                <Typography variant="h5">{realEstate.Name}</Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Description:
                </Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: realEstate.Description }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Cost:
                </Typography>
                <Typography variant="body1">{realEstate.Cost}</Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Location:
                </Typography>
                <Typography variant="body1">{realEstate.Location}</Typography>
                <Button variant="contained" onClick={handleGoBack}>
                  Go Back
                </Button>
              </div>
            ) : (
              <Typography>Real estate not found.</Typography>
            )}
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export default RealEstateDetail;