import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchCharacters } from './characterSlice';

export const redirectToYourCharacters = createAction('downtime/redirectToYourCharacters');

export const fetchDowntimeActivitiesAsync = createAsyncThunk(
  'downtime/fetchActivities',
  async (_, { dispatch, getState }) => {
    try {
      console.log('Fetching downtime activities...');
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/downtime-activity/');
      const data = await response.json();

      // Fetch real estate options for each character owned by the current user
      const userCharacters = getState().characters.characters.filter(
        (character) => character.Player_ID === getState().user.user.id
      );
      userCharacters.forEach((character) => {
        dispatch(fetchRealEstateToCharacter(character.id));
      });

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDowntimeToCharacterAsync = createAsyncThunk(
  'downtime/fetchDowntimeToCharacter',
  async (_, { getState }) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const characters = getState().characters.characters;
      const user = getState().user.user;

      let allDowntimeActivities = [];

      for (const character of characters) {
        if (character.Player_ID === user?.id) {
          const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/get-downtime-to-character/${character.id}/`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
          }

          const data = await response.json();

          const downtimeActivities = Array.isArray(data.results) ? data.results : [data.results];
          allDowntimeActivities = [...allDowntimeActivities, ...downtimeActivities];
        }
      }

      return allDowntimeActivities;
    } catch (error) {
      console.error('Error in fetchDowntimeToCharacterAsync:', error);
      throw error;
    }
  }
);

export const purchaseDowntimeActivity = createAsyncThunk(
  'downtime/purchaseActivity',
  async ({ characterId, activityId, discountedEffortCost, discountedCost }, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/purchaseDowntimeActivity/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ characterId, activityId, discountedEffortCost, discountedCost }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      await dispatch(fetchCharacters());
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addCharacterLogForDowntimeActivity = createAction(
  'downtime/addCharacterLog',
  (logData) => ({
    payload: logData,
  })
);

export const fetchRealEstateToCharacter = createAsyncThunk(
  'downtime/fetchRealEstateToCharacter',
  async (characterId, { dispatch }) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/get-real-estate-to-character/${characterId}/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      return data.results;
    } catch (error) {
      throw error;
    }
  }
);

const downtimeSlice = createSlice({
  name: 'downtime',
  initialState: {
    activities: [],
    status: 'idle',
    error: null,
    realEstateOptions: [],
    realEstateToCharacter: [],
    downtimeToCharacter: [],
  },
  reducers: {
    setRealEstateToCharacter: (state, action) => {
      state.realEstateToCharacter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDowntimeActivitiesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDowntimeActivitiesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.activities = action.payload.results;
      })
      .addCase(fetchDowntimeActivitiesAsync.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })
      .addCase(purchaseDowntimeActivity.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(purchaseDowntimeActivity.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success('Enjoy your downtime, you\'ve earned it!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 20000000000,
        });
      })
      .addCase(purchaseDowntimeActivity.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.payload;
        if (action.payload === 'Not enough effort or gold to purchase the activity.') {
          toast.error(action.payload, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            zIndex: 20000000000,
          });
        } else {
          toast.error('An error occurred while purchasing the downtime activity.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            zIndex: 20000000000,
          });
        }
      })
      .addCase(redirectToYourCharacters, () => {
        const navigate = useNavigate();
        navigate('/dashboard/your-characters');
      })
      .addCase(fetchRealEstateToCharacter.fulfilled, (state, action) => {
        state.realEstateToCharacter = action.payload;
      })
      .addCase(fetchDowntimeToCharacterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDowntimeToCharacterAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.downtimeToCharacter = action.payload;
        state.error = null; // Clear the error state
      })
      .addCase(fetchDowntimeToCharacterAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default downtimeSlice.reducer;