// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`
}

export const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password')
}

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500'
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    magicItemDetail: (itemId) => path(ROOTS_DASHBOARD, `/magic-item/${itemId}`), // Dynamic path
    realEstateDetail: (realEstateId) => path(ROOTS_DASHBOARD, `/real-estate/${realEstateId}`), // Add this line
    downtimeActivityDetail: (downtimeActivityId) => `${PATH_DASHBOARD.general.downtimeActivities}/${downtimeActivityId}`,
    app: path(ROOTS_DASHBOARD, '/app'),
    yourcharacters: path(ROOTS_DASHBOARD, '/your-characters'),
    yourcharacterstabs: path(ROOTS_DASHBOARD, '/your-characters-tabs'),
    characterProfile: (characterId) => path(ROOTS_DASHBOARD, `/character/${characterId}`),
    purchaseItems: path(ROOTS_DASHBOARD, '/purchase-items'),
    spendDowntime: path(ROOTS_DASHBOARD, '/spend-downtime'),
    yourItems: path(ROOTS_DASHBOARD, '/your-items'),
    characterlog: path(ROOTS_DASHBOARD, '/character-log'),
    managegames: path(ROOTS_DASHBOARD, '/manage-games'),
    manageplayers: path(ROOTS_DASHBOARD, '/manage-players'),
    managecharacters: path(ROOTS_DASHBOARD, '/manage-characters'),
    managedowntime: path(ROOTS_DASHBOARD, '/manage-downtime'),
    manageitems: path(ROOTS_DASHBOARD, '/manage-items'),
    managerealestate: path(ROOTS_DASHBOARD, '/manage-realestate'),
    gametabs: path(ROOTS_DASHBOARD, '/game-tabs'),
  },

  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),

    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
}

export const PATH_DOCS = ''
export const API_DOCS = ''
