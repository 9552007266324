import { Suspense, lazy } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'

import DashboardLayout from '../layouts/dashboard'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
// guards
import GuestGuard from '../guards/GuestGuard'
import AuthGuard from '../guards/AuthGuard'
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config'
// components
import LoadingScreen from '../components/LoadingScreen'
import MagicItemDetail from '../pages/dashboard/MagicItemDetail'; // Import your MagicItemDetail component
import RealEstateDetail from '../pages/dashboard/RealEstateDetail'; // Import your MagicItemDetail component
import CharacterDetails from '../pages/dashboard/CharacterDetails'; // Import your CharacterDetails component
import CharacterProfile from '../pages/dashboard/CharacterProfile'; // Import your CharacterDetails component






// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'magic-item/:itemId', element: <MagicItemDetail /> }, // Add this line for 'dashboard/magic-item/:itemId'
        { path: 'real-estate/:realEstateId', element: <RealEstateDetail /> }, // Add this line for 'dashboard/real-estate-detail/:realEstateId'
        //{ path: 'character/:characterId', element: <CharacterDetails /> }, // Add this line for 'dashboard/character/:characterId'
        { path: 'character/:characterId', element: <CharacterProfile /> },

        { path: 'app', element: <GeneralApp /> }, // Add this line for 'dashboard/app'
        { path: 'your-characters', element: <YourCharacters /> },
        { path: 'your-characters-tabs', element: <YourCharactersTabs /> },

        { path: 'your-items', element: <YourItems />},
        { path: 'purchase-items', element: <PurchaseItems /> },
        { path: 'spend-downtime', element: <SpendDownTime /> },
        { path: 'character-log', element: <CharacterLog /> },
        { path: 'manage-games', element: <ManageGames /> },
        { path: 'game-tabs', element: <GameTabs /> },
        { path: 'manage-players', element: <ManagePlayers /> },
        { path: 'manage-characters', element: <ManageCharacters />},
        { path: 'manage-downtime', element: <ManageDowntime /> },
        { path: 'manage-items', element: <ManageItems /> },
        { path: 'manage-realestate', element: <ManageRealEstate /> },
        {
          path: 'manage-games',
          element: (
            <AuthGuard>
              <DashboardLayout />
              <ManageGames /> {/* Add this line for 'dashboard/manage-games' */}
            </AuthGuard>
          )
        },
        {
          path: 'game-tabs',
          element: (
            <AuthGuard>
              <DashboardLayout />
              <GameTabs /> {/* Add this line for 'dashboard/manage-games' */}
            </AuthGuard>
          )
        },
        {
          path: 'manage-players',
          element: (
            <AuthGuard>
              <DashboardLayout />
              <ManagePlayers /> {/* Add this line for 'dashboard/manage-players' */}
            </AuthGuard>
          )
        },      
        {
          path: 'manage-characters',
          element: (
            <AuthGuard>
              <DashboardLayout />
              <ManageCharacters /> {/* Add this line for 'dashboard/manage-characters' */}
            </AuthGuard>
          )
        },
        {
          path: 'manage-downtime',
          element: (
            <AuthGuard>
              <DashboardLayout />
              <ManageDowntime /> {/* Add this line for 'dashboard/manage-downtime' */}
            </AuthGuard>
          )
        },
        {
          path: 'manage-items',
          element: (
            <AuthGuard>
              <DashboardLayout />
              <ManageItems /> {/* Add this line for 'dashboard/manage-items' */}
            </AuthGuard>
          )
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'account', element: <UserAccount /> }
          ]
        }
      ]
    },


    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/auth/login" replace /> }
      ]
    },

    { path: '*', element: <Navigate to="/auth/login" replace /> }
  ])
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')))
const Register = Loadable(lazy(() => import('../pages/auth/Register')))
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')))
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')))
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')))

const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')))
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')))

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')))
const YourCharacters = Loadable(lazy(() => import('../pages/dashboard/YourCharacters'))) // Import this component
const YourCharactersTabs = Loadable(lazy(() => import('../pages/dashboard/YourCharactersTabs'))) // Import this component

const YourItems = Loadable(lazy(() => import('../pages/dashboard/YourItems'))) // Import this component
const PurchaseItems = Loadable(lazy(() => import('../pages/dashboard/PurchaseItems'))) // Import this component
const SpendDownTime = Loadable(lazy(() => import('../pages/dashboard/SpendDownTime'))) // Import this component
const ManageGames = Loadable(lazy(() => import('../pages/dashboard/ManageGames'))) // Import this component
const GameTabs = Loadable(lazy(() => import('../pages/dashboard/GameTabs'))) // Import this component
const ManagePlayers = Loadable(lazy(() => import('../pages/dashboard/ManagePlayers'))) // Import this component
const ManageCharacters = Loadable(lazy(() => import('../pages/dashboard/ManageCharacters'))) // Import this component
const CharacterLog = Loadable(lazy(() => import('../pages/dashboard/CharacterLog')))
const ManageDowntime = Loadable(lazy(() => import('../pages/dashboard/ManageDowntime'))) // Import this component
const ManageItems = Loadable(lazy(() => import('../pages/dashboard/ManageItems'))) // Import this component
const ManageRealEstate = Loadable(lazy(() => import('../pages/dashboard/ManageRealEstate'))) // Import this component
const Page500 = Loadable(lazy(() => import('../pages/Page500')))
const Page403 = Loadable(lazy(() => import('../pages/Page403')))
const Page404 = Loadable(lazy(() => import('../pages/Page404')))
