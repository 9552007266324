import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

// Create an async thunk for fetching purchase items
export const fetchPurchaseItemsAsync = createAsyncThunk(
  'purchaseItems/fetchItems',
  async () => {
    try {
      // Make an API request to fetch purchase items from your Django backend
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/store-inventory/');
      const data = await response.json();

      // Filter the items with quantity greater than 0
      const filteredData = data.results.filter((item) => item.quantity_in_stock > 0);

      // Replace the original data with filtered data before returning it
      return { ...data, results: filteredData };
    } catch (error) {
      throw error;
    }
  }
);
// Create an async thunk for fetching the next repopulation time
export const fetchNextRepopulationTimeAsync = createAsyncThunk(
  'purchaseItems/fetchNextRepopulationTime',
  async () => {
    try {
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/next-repopulation/');
      const data = await response.json();
      if (data.next_repopulation_time) {
        return data.next_repopulation_time;
      } else {
        throw new Error('Failed to fetch next repopulation time');
      }
    } catch (error) {
      throw error;
    }
  }
);
// Create an async thunk for purchasing and adding a purchase item
export const purchaseMagicItem = createAsyncThunk(
  'purchaseItems/purchaseItem',
  async ({ characterId, itemId, userId }, { dispatch }) => {
    try {
      // Log the characterId and itemId before making the API request
      ////console.log('ThunkuserId:', userId);
      //console.log('ThunkcharacterId:', characterId);
      //console.log('ThunkitemId:', itemId);

      // Make an API request to your Django backend to purchase and add the purchase item
      const accessToken = localStorage.getItem('accessToken'); // Retrieve JWT token from local storage
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/purchaseMagicItem/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`, // Include JWT token in headers
        },
        body: JSON.stringify({ characterId, itemId, userId }),
      });

      if (!response.ok) {
        // Handle the error response here if needed
        throw new Error('Failed to purchase item');
      }

      const data = await response.json();

      // If the purchase was successful, update the Redux state
      if (data.success) {
        // Dispatch an action to add the purchased item to Redux
        dispatch(addPurchaseItem({ purchaseItem: data.magicItem }));
        // Dispatch an action to decrement the quantity in StoreInventory
        dispatch(decrementQuantityInStoreInventory(itemId));
        // Dispatch an action to log the character purchase
        dispatch(logCharacterPurchase(data.magicItem, characterId, userId));
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

// Define action to add purchased purchase item to Redux
export const addPurchaseItem = createAction('purchaseItems/addPurchaseItem', (payload) => ({
  payload,
}));

// Define action to decrement quantity in StoreInventory
export const decrementQuantityInStoreInventory = createAction(
  'storeInventory/decrementQuantity',
  (itemId) => ({
    payload: itemId,
  })
);

// Define action to log character purchase
export const logCharacterPurchase = createAction(
  'characterLogs/logCharacterPurchase',
  (magicItem, characterId, userId) => ({
    payload: {
      magicItem,
      characterId,
      userId,
    },
  })
);

const purchaseItemSlice = createSlice({
  name: 'purchaseItems',
  initialState: {
    purchaseItems: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // No need to modify reducers as they only mutate state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseItemsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPurchaseItemsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.purchaseItems = action.payload.results; // Update to action.payload.results
      })
      .addCase(fetchPurchaseItemsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchNextRepopulationTimeAsync.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchNextRepopulationTimeAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.nextRepopulationTime = action.payload;
      })
      .addCase(fetchNextRepopulationTimeAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(purchaseMagicItem.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(purchaseMagicItem.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(purchaseMagicItem.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default purchaseItemSlice.reducer;