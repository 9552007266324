import { useContext } from 'react';
//
import { AuthContext } from '../contexts/JWTContext';
// import { AuthContext } from '../contexts/Auth0Context';
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/AwsCognitoContext';

// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    console.error('Auth context is missing or not provided.');
  }

  // Ensure that the context includes the logout function
  const { logout } = context;

  return { ...context, logout }; // Include the logout function in the returned object
};

export default useAuth;