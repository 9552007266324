// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';

// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Welcome', path: PATH_DASHBOARD.general.app, icon: ICONS.kanban },
      { title: 'Your Characters', path: PATH_DASHBOARD.general.yourcharacterstabs, icon: ICONS.invoice },
      { title: 'Purchase Items', path: PATH_DASHBOARD.general.purchaseItems, icon: ICONS.cart },
      { title: 'Spend Downtime', path: PATH_DASHBOARD.general.spendDowntime, icon: ICONS.user },
      { title: 'Real Estate', path: PATH_DASHBOARD.general.managerealestate, icon: ICONS.banking },
    ],
  },
];

export const getFilteredNavConfig = (user) => {
  const isSuperuser = user?.user?.is_superuser || false;
  console.log(`Is the current user a superuser? ${isSuperuser}`);

  const gamesItemExists = navConfig[0].items.some((item) => item.title === 'Games');

  if (isSuperuser && !gamesItemExists) {
    navConfig[0].items.push({ title: 'Games', path: PATH_DASHBOARD.general.gametabs, icon: ICONS.calendar });
  }

  console.log('Filtered navigation config:', navConfig);
  return navConfig;
};

export default navConfig;