import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCharacters } from './characterSlice';
import { createCharacterLogAsync } from './characterLogSlice';

export const fetchRealEstateAsync = createAsyncThunk(
  'realEstate/fetchProperties',
  async (_, { dispatch }) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/real-estate/', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      
      await dispatch(fetchCharacters());
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchRealEstateToCharacterAsync = createAsyncThunk(
  'realEstate/fetchRealEstateToCharacter',
  async (characterId, { dispatch }) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/real-estate-to-character/?character_id=${characterId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      await dispatch(fetchCharacters());
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const purchaseRealEstate = createAsyncThunk(
  'realEstate/purchaseProperty',
  async ({ characterId, propertyId }, { dispatch, getState }) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/purchaseRealEstate/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ characterId, propertyId }),
      });
      const data = await response.json();
      await dispatch(fetchCharacters());
      const state = getState();
      const property = state.realEstate.properties.find((property) => property.ID === propertyId);
      const character = state.characters.characters.find((character) => character.id === characterId);
      const logData = {
        free_item_received: false,
        event_type: 'Spend Real Estate',
        description: `Purchased real estate: ${property.Name}`,
        timestamp: new Date().toISOString(),
        modification_type: null,
        loot_received: null,
        character: characterId,
        character_name: character ? character.Name : '',
        player_id: state.user.user.id,
      };
      await dispatch(createCharacterLogAsync(logData));
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  properties: [],
  realEstateToCharacter: [],
  status: 'idle',
  error: null,
};

const realEstateSlice = createSlice({
  name: 'realEstate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRealEstateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRealEstateAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.properties = action.payload.results;
      })
      .addCase(fetchRealEstateAsync.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })
      .addCase(fetchRealEstateToCharacterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRealEstateToCharacterAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.realEstateToCharacter = action.payload.results;
      })
      .addCase(fetchRealEstateToCharacterAsync.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })
      .addCase(purchaseRealEstate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(purchaseRealEstate.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(purchaseRealEstate.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      });
  },
});

export default realEstateSlice.reducer;