//YourItemSlice.js
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

// Modify fetchYourItemsAsync action to structure data consistently
// Modify fetchYourItemsAsync action to fetch magic items owned by characters owned by the user

export const fetchYourItemsAsync = createAsyncThunk(
  'yourItems/fetchItems',
  async (_, { getState }) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const characters = getState().characters.characters;
      const user = getState().user.user;

      let allItems = [];

      for (const character of characters) {
        if (character.Player_ID === user?.id) {
          let nextUrl = `https://wellmetadmin.wellmet.academy/api/v1/get-magic-item-to-character/${character.id}/`;

          while (nextUrl) {
            const response = await fetch(nextUrl, {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            });

            if (!response.ok) {
              throw new Error('Failed to fetch your items');
            }

            const data = await response.json();

            allItems = [...allItems, ...data.results];
            nextUrl = data.next;
          }
        }
      }

      return allItems;
    } catch (error) {
      throw error;
    }
  }
);

// Create an async thunk for selling your item
export const sellYourItem = createAsyncThunk(
  'yourItems/sellItem',
  async ({ itemId, characterName }, { dispatch }) => {
    try {
      // Make an API request to your Django backend to sell the your item
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/sell-your-item/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId, characterName }),
      });

      if (!response.ok) {
        // Handle the error response here if needed
        throw new Error('Failed to sell item');
      }

      const data = await response.json();

      // If the sale was successful, update the Redux state
      if (data.success) {
        // Dispatch an action to remove the sold item from Redux
        dispatch(removeYourItem(itemId));
        // Dispatch an action to increment the quantity in your inventory
        dispatch(incrementQuantityInYourInventory(itemId));
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const discardYourItem = createAsyncThunk(
  'yourItems/discardItem',
  async ({ itemId, characterName }, { dispatch }) => {
    try {
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/discard-your-item/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId, characterName }),
      });

      if (!response.ok) {
        throw new Error('Failed to discard item');
      }

      const data = await response.json();

      if (data.success) {
        dispatch(removeYourItem(itemId));
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);
// Define action to remove sold your item from Redux
export const removeYourItem = createAction(
  'yourItems/removeItem',
  (itemId) => ({
    payload: itemId,
  })
);

// Define action to increment quantity in your inventory
export const incrementQuantityInYourInventory = createAction(
  'yourInventory/incrementQuantity',
  (itemId) => ({
    payload: itemId,
  })
);
export const fetchCharacterItemsAsync = createAsyncThunk(
  'items/fetchCharacterItems',
  async (characterId, { rejectWithValue }) => {
    try {
      const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/get-character-items/${characterId}/`);
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetYourItems = createAction('yourItems/resetItems');

const yourItemSlice = createSlice({
  name: 'yourItems',
  initialState: {
    yourItems: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    [removeYourItem.type]: (state, action) => {
      // Remove the sold your item from the state
      state.yourItems = state.yourItems.filter((item) => item.id !== action.payload);
    },
    [incrementQuantityInYourInventory.type]: (state, action) => {
      const itemId = action.payload;
      // Increment the quantity of the item in your inventory
      const item = state.yourItems.find((item) => item.id === itemId);
      if (item) {
        item.quantity_in_your_inventory += 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchYourItemsAsync.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchYourItemsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.yourItems = action.payload;
      })
      .addCase(fetchYourItemsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(sellYourItem.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(sellYourItem.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(sellYourItem.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(discardYourItem.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(discardYourItem.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(discardYourItem.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCharacterItemsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCharacterItemsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.characterItems = action.payload;
      })
      .addCase(fetchCharacterItemsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default yourItemSlice.reducer;