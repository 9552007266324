import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state for PlayerCharacters
const initialState = {
  characters: [],
  charactersWithUsers: [],
  currentCharacter: null,
  loading: false,
  error: null,
};

// Define an async thunk to fetch characters with users
export const fetchCharactersWithUsers = createAsyncThunk(
  'characters/fetchWithUsers',
  async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/characters-with-users/', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching characters with users:', error);
      throw error;
    }
  }
);

// Define an async thunk to fetch characters
export const fetchCharacters = createAsyncThunk('characters/fetch', async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/characters/', {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
});

// Define an async thunk to claim a character
export const claimCharacter = createAsyncThunk('characters/claim', async ({ characterId, userId }) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/characters/${characterId}/claim/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ userId }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
});

// Define an async thunk to create a character
export const createCharacter = createAsyncThunk('characters/create', async (characterData) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/characters/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(characterData),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
});

// Define an async thunk to fetch a character profile
export const fetchCharacterProfile = createAsyncThunk(
  'characters/fetchProfile',
  async (characterId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/characters/${characterId}/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch character profile');
      }

      const characterData = await response.json();

      const magicItemsResponse = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/get-magic-item-to-character/${characterId}/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!magicItemsResponse.ok) {
        throw new Error('Failed to fetch magic items for character');
      }

      const magicItemsData = await magicItemsResponse.json();

      return {
        ...characterData,
        magicItems: magicItemsData.results,
      };
    } catch (error) {
      throw error;
    }
  }
);

// Create a Redux slice for PlayerCharacters
const characterSlice = createSlice({
  name: 'characters',
  initialState,
  reducers: {
    // Define any synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCharacters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCharacters.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.characters = action.payload.results;
      })
      .addCase(fetchCharacters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCharactersWithUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCharactersWithUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.charactersWithUsers = action.payload;
      })
      .addCase(fetchCharactersWithUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(claimCharacter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(claimCharacter.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // Update character's Player_ID here if needed
      })
      .addCase(claimCharacter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createCharacter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCharacter.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // Update state with the newly created character if needed
      })
      .addCase(createCharacter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCharacterProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCharacterProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.currentCharacter = action.payload;
      })
      .addCase(fetchCharacterProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default characterSlice.reducer;

// Selectors
export const selectCharacters = (state) => state.characters.characters;
export const selectCharactersWithUsers = (state) => state.characters.charactersWithUsers;
export const selectCurrentCharacter = (state) => state.characters.currentCharacter;
export const selectCharactersLoading = (state) => state.characters.loading;
export const selectCharactersError = (state) => state.characters.error;