import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchTrades = createAsyncThunk('trades/fetchTrades', async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/trades/', {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    console.log('Fetched trades data:', data); // Add this line
    return data;
  } catch (error) {
    throw error;
  }
});

export const fetchCharacterItemsAsync = createAsyncThunk('characterItems/fetchCharacterItemsAsync', async (characterId) => {
  const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/get-character-items/${characterId}/`);
  console.log('Fetched character items data:', data); // Add this line
  return response.json();
});

export const createTrade = createAsyncThunk('trades/createTrade', async (tradeData) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const requestData = {
      ...tradeData,
      effort: Number(tradeData.effort),
      gold: Number(tradeData.gold),
    };

    // Log the sender_items and receiver_items before sending the request
    console.log('Sender items:', tradeData.sender_items);
    console.log('Receiver items:', tradeData.receiver_items);

    console.log('Trade data:', requestData); // Log the trade data before sending the request

    const response = await fetch('https://wellmetadmin.wellmet.academy/api/v1/trades/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to create trade');
    }
    return data;
  } catch (error) {
    throw error;
  }
});

export const acceptTrade = createAsyncThunk('trades/acceptTrade', async (tradeId) => {
  const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/trades/${tradeId}/accept/`, {
    method: 'POST',
  });
  return response.json();
});

export const declineTrade = createAsyncThunk('trades/declineTrade', async (tradeId) => {
  const response = await fetch(`https://wellmetadmin.wellmet.academy/api/v1/trades/${tradeId}/decline/`, {
    method: 'POST',
  });
  return response.json();
});

const tradesSlice = createSlice({
  name: 'trades',
  initialState: {
    trades: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrades.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTrades.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.trades = action.payload;
      })
      .addCase(fetchTrades.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createTrade.fulfilled, (state, action) => {
        state.trades.push(action.payload);
      })
      .addCase(acceptTrade.fulfilled, (state, action) => {
        const trade = state.trades.find(trade => trade.id === action.payload.id);
        trade.status = 'accepted';
      })
      .addCase(declineTrade.fulfilled, (state, action) => {
        const trade = state.trades.find(trade => trade.id === action.payload.id);
        trade.status = 'declined';
      });
  },
});

export default tradesSlice.reducer;