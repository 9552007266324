import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
};

const handlers = {
  INITIALIZE: (state, action) => ({
    ...state,
    isAuthenticated: action.payload.isAuthenticated,
    isInitialized: true,
  }),
  LOGIN: (state) => ({
    ...state,
    isAuthenticated: true,
  }),
  LOGOUT: () => initialState,
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getAccessToken: () => null, // Function to get the access token
});

AuthContext.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve from local storage

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post('https://wellmetadmin.wellmet.academy/api/v1/accounts/login/', {
        email,
        password,
      });

      const { access } = response.data; // Extract access token

      setSession(access);
      localStorage.setItem('accessToken', access); // Store access token in local storage

      dispatch({
        type: 'LOGIN',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const register = async (email, password, name) => { // Adjusted to use 'name' instead of 'firstName' and 'lastName'
    try {
      const response = await axios.post('https://wellmetadmin.wellmet.academy/api/v1/register/', {
        email,
        password,
        name,
      });

      const { accessToken } = response.data; // Extract access token

      localStorage.setItem('accessToken', accessToken); // Store access token in local storage

      dispatch({
        type: 'LOGIN',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const logout = async () => {
    localStorage.removeItem('accessToken'); // Remove access token from local storage
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const getAccessToken = () => {
    return localStorage.getItem('accessToken'); // Retrieve access token from local storage
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        getAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
