import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCharacterProfile } from '../../redux/slices/characterSlice';
import { fetchRealEstateToCharacterAsync } from '../../redux/slices/realEstateSlice';
import { fetchDowntimeToCharacterAsync } from '../../redux/slices/downtimeSlice';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
} from '@mui/material';

const CharacterProfile = () => {
  const { characterId } = useParams();
  const dispatch = useDispatch();
  const character = useSelector((state) => state.characters.currentCharacter);
  const loading = useSelector((state) => state.characters.loading);
  const error = useSelector((state) => state.characters.error);
  const realEstateToCharacter = useSelector((state) => state.realEstate.realEstateToCharacter);
  const downtimeToCharacter = useSelector((state) => state.downtime.downtimeToCharacter);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(fetchCharacterProfile(characterId));
    dispatch(fetchRealEstateToCharacterAsync(characterId));
    dispatch(fetchDowntimeToCharacterAsync(characterId));
  }, [dispatch, characterId]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', minHeight: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  if (!character) {
    return null;
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4 }}>
        <Card>
          <CardHeader title={character.Name} />
          <CardContent>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="Character Details" />
              <Tab label="Magic Items" />
              <Tab label="Real Estate" />
              <Tab label="Downtime Activities" />
            </Tabs>
            {activeTab === 0 && (
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">Level</TableCell>
                        <TableCell>{character.Level}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Player Name</TableCell>
                        <TableCell>{character.PlayerName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Race</TableCell>
                        <TableCell>{character.Race || '-'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Class</TableCell>
                        <TableCell>{character.Class || '-'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Pronouns</TableCell>
                        <TableCell>{character.Pronouns || '-'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">XP</TableCell>
                        <TableCell>{character.XP}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Gold</TableCell>
                        <TableCell>{character.Gold}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Effort</TableCell>
                        <TableCell>{character.Effort}</TableCell>
                      </TableRow>
                      </TableBody>
                </Table>
              </TableContainer>
            )}
            {activeTab === 1 && (
                            <div>
                            <Typography variant="h6">Magic Items</Typography>
                            {console.log('Magic Items:', character.magicItems)}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Rarity</TableCell>
                      <TableCell>Cost</TableCell>
                      <TableCell>Sale Price</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {character.magicItems && character.magicItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.magic_item_name}</TableCell>
                        <TableCell>{item.magic_item_rarity}</TableCell>
                        <TableCell>{item.magic_item_cost}</TableCell>
                        <TableCell>{item.sale_price}</TableCell>
                        <TableCell>{item.magic_item_description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </div>
            )}
            {activeTab === 2 && (
              <div>
                <Typography variant="h6">Real Estate</Typography>
                {console.log('Real Estate To Character:', realEstateToCharacter)}
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Cost</TableCell>
                        <TableCell>Purchase Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {realEstateToCharacter && realEstateToCharacter.map((realEstate) => (
                        <TableRow key={realEstate.id}>
                          <TableCell>{realEstate.Real_Estate_Name}</TableCell>
                          <TableCell>{realEstate.real_estate_description}</TableCell>
                          <TableCell>{realEstate.real_estate_cost}</TableCell>
                          <TableCell>{new Date(realEstate.purchase_date).toLocaleDateString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
                        {activeTab === 3 && (
              <div>
                <Typography variant="h6">Downtime Activities</Typography>
                {console.log('Downtime To Character:', downtimeToCharacter)}
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Activity Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Purchase Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {downtimeToCharacter && downtimeToCharacter.map((activity) => (
                        <TableRow key={activity.id}>
                          <TableCell>{activity.downtime_activity_name}</TableCell>
                          <TableCell>{activity.downtime_activity_description}</TableCell>
                          <TableCell>{new Date(activity.created_at).toLocaleDateString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default CharacterProfile;